import React from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import logo from './../../images/pointlabs_logo.png';

class Header extends React.Component {
  render () {
    return (
      <header className='row'>
        <div className='col-lg-3'>
          <a href='./'><img src={ logo } className='logo' alt='PointLabs logo' /></a>
        </div>
      </header>
    );
  }
}

export default Header;