import React from 'react';
import './Home.css';
import Contact from './../Contact/Contact';


class Home extends React.Component {
    render () {
        return (
            <div className='container-fluid'>
                <div id="carouselHome" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active seattle">
                        </div>
                        <div className="carousel-item  carousal1">
                        </div>
                        <div className="carousel-item  carousal2">
                        </div>
                    </div>
                    <a className="carousel-control-prev" href="#carouselHome" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselHome" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>

                <br />
                <div className='card-deck'>
                    <div className='card'>
                        <div className='card-body'>
                            <img className='card-img-top' src={ require( "./../../images/pic3.jpg" ) } height='120px' alt='' />
                            <h5 clssName='card-title'>Product Development</h5>
                            <p className='card-text'>
                                We engage in incubating ideas to product realization from inception, product implementation to delivery,
                                providing an end to end in-house software development using latest technologies for business need.
                            </p>
                        </div>
                    </div>

                    <div className='card'>
                        <div className='card-body'>
                            <img className='card-img-top' src={ require( "./../../images/pic2.jpg" ) } height='120px' alt='' />
                            <h5 clssName='card-title'>Software Solutions</h5>
                            <p className='card-text'>
                                We engage with companies, small businesses and individual developers to provide in-house software
                                services to meet project requirements that strive to deliver quality results within time-to-market constraints.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;