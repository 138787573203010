import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Home from './../Home/Home';
import Header from './../Header/Header';
import Footer from './../Footer/Footer';
import Policy from './../Policy/Policy';
import Contact from './../Contact/Contact';
import Attributions from './../Attributions/Attributions';
import CookieConsent from 'react-cookie-consent';

class App extends React.Component {
  render() {
    return (
      <div className='container-fluid'>
        <div className='row main'>
          <Header />
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/home' exact component={Home} />
            <Route path='/contact' exact component={Contact} />
            <Route path='/policy' exact component={Policy} />
            <Route path='/attributions' exact component={Attributions} />
            <Redirect to='/' />
          </Switch>
          <Footer />
        </div>
        <CookieConsent
          buttonText='Accept'
          enableDeclineButton={true}
          flipButtons={true}
          onDecline={() => { }}
          cookieName='pointlabsCookieConsent5'
          cookieValue='PointLab Solutions Cookie Consent accepted'
          setDeclineCookie={true}
          expires={1}
        >
          This website uses cookies necessary for the functioning of our website that provides services tailored to your interests.
          We may share your information with our advertising and analytics partners. By Clicking 'Accept', you agree to our use of cookies.
          &nbsp;<a href='./policy'>Learn more</a>
        </CookieConsent>
      </div>
    );
  }
}

export default App;
