import React, { useState } from 'react';
import {
    Row, Col, Collapse,
    Card, CardBody, CardHeader,
} from 'reactstrap';
import './Attributions.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';


const licenses = require('../../data/licenses.json');

const LicenseList = (props) => {
    const [itemSelected, SetItemSelected] = useState(-1);

    const handleClick = (event) => {
        if (itemSelected !== event.currentTarget.dataset.index) {
            SetItemSelected(event.currentTarget.dataset.index);
        } else {
            SetItemSelected(-1);
        }
    }

    const isOpen = (index) => {
        // eslint-disable-next-line 
        return (itemSelected == index)
    }

    return (
        <div>
            <p className='notice'>
                {licenses.notice} <br /><br />
                {licenses.notice2}<br /><br />
                {licenses.notice3}
            </p>
            {
                licenses.list.map((item, index) =>
                    <div key={index.toString()}>
                        <Card>
                            <CardHeader className={isOpen(index) ? 'card-active' : 'card-inactive'}>
                                <Row onClick={handleClick} data-index={index}>
                                    <Col md={4}>
                                        {
                                            item.title
                                        }
                                    </Col>
                                    <Col md={7}>
                                        {
                                            item.license_type
                                        }
                                    </Col>
                                    <Col className='text-right' md={1}>
                                        <FontAwesomeIcon className={isOpen(index) ? '' : 'site-primary-color'} icon={isOpen(index) ? faChevronUp : faChevronDown} />
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Collapse isOpen={isOpen(index)}>
                                <CardBody>
                                    <p>
                                        {item.text}
                                    </p>
                                    <a href={item.home_link} target='_blank' rel='noreferrer noopener'>{item.home_link}</a><br />
                                    <a href={item.license_link} target='_blank' rel='noreferrer noopener'>{item.license_link}</a>
                                </CardBody>
                            </Collapse>
                        </Card>
                    </div>
                )
            }
        </div>
    );
}


class Policy extends React.Component {
    render() {
        return (
            <div className='license-container'>
                <Row>
                    <Col md={12}>
                        <div className='list-container'>
                            <LicenseList />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Policy;