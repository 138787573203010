import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

class Footer extends React.Component {
/*
                <div class='col-lg-2'>
                    <ul class='list-inline'>
                        <li class='list-inline-item'><Link to='/contact'><i class="fab fa-linkedin"></i></Link></li>&nbsp;
                        <li class='list-inline-item'><Link to='/contact'><i class="fab fa-twitter-square"></i></Link></li>&nbsp;
                        <li class='list-inline-item'><Link to='/contact'><i class="fab fa-facebook-square"></i></Link></li>
                    </ul>
                </div>

*/
    render() {
        return (
            <footer className='row'>
                <div className='col-lg-12'>

                    <div className='footer-links'>
                        <Link className='footer-links' to='/attributions'><i className="fab fa-creative-commons-by"></i>&nbsp;Attributions</Link> &nbsp;
                        <Link className='footer-links' to='/policy'><i className="fas fa-user-secret"></i>&nbsp;Privacy Policy</Link>
                    </div>

                    <div className='copyright'>Copyright &copy; 2023 PointLab Solutions LLC. &nbsp;</div>
                </div>
            </footer>
        );
    }
}

export default Footer;