import React from 'react';
import './Contact.css';

class Contact extends React.Component {

    onSubmit = ( event ) => {
        event.preventDefault();
        console.log( 'Form submitted' );
    };

    render () {
        return (
            <section className='contactus' >
                <h2 className='h1-responsive font-weight-bold text-center'>Contact us</h2>
                <p className='text-center'>
                    Our team will respond back within 24-48 business hrs to answer your queries.
                </p>
                <div className='row'>
                    <div className='col-md-9'>
                        <form className='form' id='contactusForm' name='contactus' onSubmit={ this.onSubmit }>
                            <div className='form-row'>
                                <div className='col-md-6'>
                                    <div className='md-form mb-0'>
                                        <input type='text' id='name' className='form-control' placeholder='Your name' required />
                                        <div className='invalid-feedback'>Please enter a name</div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='md-form mb-0'>
                                        <input type='email' id='email' name='email' className='form-control' placeholder='Your email' required />
                                        <small id='emailInfoNotice' className='form-text'>We'll never share your email with any one</small>
                                    </div>
                                </div>
                            </div>

                            <div className='form-row'>
                                <div className='col-md-12'>
                                    <div className='md-form mb-0'>
                                        <select className='custom-select custom-select-md mb-3'>
                                            <option value='Feedback' defaultValue>Feedback</option>
                                            <option value='Enquiry'>Enquiry</option>
                                            <option value='Other'>Other</option>
                                        </select>
                                        <input type='text' id='subject' name='subject' className='form-control' placeholder='Subject' required />
                                    </div>
                                </div>
                            </div>

                            <div className='form-row'>
                                <div className='col-md-12'>
                                    <div className='md-form mb-0'>
                                        <label htmlFor='message'>Your message *</label><br />
                                        <textarea id='message' name='message' rows='5' className='form-control md-textarea' placeholder='How can we help you?' required></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className='col-md-12'>
                                    <div className='md-form mb-0 form-check custom-control custom-checkbox'>
                                        <input type='checkbox' id='chkMailingList' name='chkMailList' className='form-check-input custom-control-input' />
                                        <label htmlFor='chkMailingList' className='custom-control-label'>
                                            Add me to the important product and services mailing list announcements.
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='g-recaptcha"' data-sitekey='6LfEa-4UAAAAAAX3UlOTG9vgJ13cp5PDdwaHZig2'></div>
                            <div className='text-center text-md-left'>
                                <button id='submit' type='submit' className='btn btn-primary'>
                                    Send Message
                            </button>
                            </div>
                            <div className='status'></div>
                        </form>
                    </div>
                    <div className='col-md-3'>
                        <div className='address'>
                            <div className='card-body '>
                                <p className='card-text'>
                                    <ul className='list-unstyled mb-0'>
                                        <li>
                                            <i className='fas fa-map-pin fa-2x'></i>
                                            <p>Seattle, WA, USA</p>
                                        </li>
                                        <li><i className='fas fa-at fa-2x'></i>
                                            <p><a className='link' href='mailto:contact@pointlabs.biz'>contact@pointlabs.biz</a></p>
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        );
    }
};

export default Contact;